import logo from "../Assets/Create React App.svg";

export default function Footer() {
    return (
        <footer className="bg-gray-900 text-white p-4 text-center flex flex-col items-center space-y-2 sticky bottom-0 top-[100vh]">
            <img src={logo} className="h-16 mb-8" alt="create react app logo" />
            <p className="text-sm mb-2">Empowering Developers with Intelligent Script Generation</p>
            <p className="text-xs mb-1">&copy; 2021-{new Date().getFullYear()} Create React App. All rights reserved.</p>
        </footer>
    );
}
