import React from 'react';

const ScriptGeneratorDocumentation = () => {
    return (
        <div className="max-w-4xl mx-auto p-8 text-white">
            <h1 className="text-3xl font-bold mb-4">Script Generation Guide</h1>

            <div className="mb-6">
                <h2 className="text-xl font-semibold mb-2">1. Enter Project Details</h2>
                <p>Fill out the form fields with your project details, including project name, package manager, whether to include TypeScript, and dependencies.</p>
            </div>

            <div className="mb-6">
                <h2 className="text-xl font-semibold mb-2">2. Select Script Type</h2>
                <p>Choose the appropriate script type for your project: JavaScript, Python, or Bash.</p>
            </div>

            <div className="mb-6">
                <h2 className="text-xl font-semibold mb-2">3. Generate Script</h2>
                <p>Click on the "Generate Script" button to create your script based on the provided details.</p>
            </div>

            <div className="mb-6">
                <h2 className="text-xl font-semibold mb-2">4. Download Script</h2>
                <p>After generating the script, you'll be prompted to download it. Save the script file in your desired location.</p>
            </div>

            <div className="mb-6 flex flex-col space-y-2">
                <h2 className="text-xl font-semibold mb-2">5. Run the Script</h2>
                <p>Before running the script, make sure you have the necessary software installed on your system:</p>

                <div className="flex flex-col space-y-4">
                    <div>
                        <h3 className="text-lg font-semibold mb-2">JavaScript</h3>
                        <p className='mb-4'>To run JavaScript scripts, you need to have Node.js installed on your system. You can download the latest version of Node.js from the <a href="https://nodejs.org/en/download/" className="text-blue-500 underline">official website</a>.</p>
                        <code className="bg-gray-600 p-2 rounded-md">node your-script-file.js</code>
                    </div>

                    <div>
                        <h3 className="text-lg font-semibold mb-2">Python</h3>
                        <p className='mb-4'>To run Python scripts, you need to have Python installed on your system. You can download the latest version of Python from the <a href="https://www.python.org/downloads/" className="text-blue-500 underline">official website</a>.</p>
                        <code className="bg-gray-600 p-2 rounded-md">python your-script-file.py</code>
                    </div>

                    <div>
                        <h3 className="text-lg font-semibold mb-2">Bash</h3>
                        <p className='mb-4'>To run Bash scripts, you need to have a Unix-like environment installed on your system. We recommend using Git Bash or Cygwin. You can download Git Bash from the <a referrerPolicy='same-origin' href="https://git-scm.com/downloads" className="text-blue-500 underline">official website</a> and Cygwin from the <a href="https://www.cygwin.com/" className="text-blue-500 underline">official website</a>.</p>
                        <code className="bg-gray-600 p-2 rounded-md">bash your-script-file.sh</code>
                    </div>
                </div>
            </div>

            <div className="mb-6">
                <h2 className="text-xl font-semibold mb-2">Installation Guide</h2>
                <p>Follow these steps to install WSL, Ubuntu, Node.js, and npm:</p>
                <ol className="list-decimal ml-6">
                    <li>Open PowerShell as Administrator.</li>
                    <li>Run the command: <code>wsl --install</code> to install WSL.</li>
                    <li>After installation, launch the Microsoft Store and search for Ubuntu.</li>
                    <li>Click on Ubuntu and install it.</li>
                    <li>Once installed, launch Ubuntu and follow the on-screen instructions to set up your user account.</li>
                    <li className='mb-4'>To install Node.js and npm in Ubuntu, use the following commands:</li>
                    <div className='flex flex-col space-y-4 max-w-xs'>
                        <code className="bg-gray-600 py-2 px-4 rounded-md mt-4">
                            sudo apt update
                        </code>
                        <br />
                        <code className="bg-gray-600 py-2 px-4 rounded-md mt-4">
                            sudo apt install nodejs
                        </code>
                        <br />
                        <code className="bg-gray-600 py-2 px-4 rounded-md mt-4">
                            sudo apt install nodejs
                        </code>
                    </div>

                </ol>
            </div>
        </div>
    );
};

export default ScriptGeneratorDocumentation;
