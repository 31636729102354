
import logo from "./Assets/Create React App.svg";
import { useNavigate } from "react-router-dom";
import AppRoutes from "./Routes/AppRoutes";
import Footer from "./Components/Footer";
import { useEffect } from "react";

export default function App() {

  useEffect(() => {
    // Check if the device width is less than a certain threshold (considered as mobile)
    const isMobile: boolean = window.innerWidth < 768; // Adjust the threshold as needed

    // Define the margin values based on whether it's mobile or not
    const xMargin: string = isMobile ? "10" : "210";
    const yMargin: string = isMobile ? "50" : "220";

    // Generate the Buy Me a Coffee widget with the dynamic margin values
    const script: HTMLScriptElement = document.createElement('script');
    script.setAttribute('data-name', 'BMC-Widget');
    script.setAttribute('data-cfasync', 'false');
    script.setAttribute('src', 'https://cdnjs.buymeacoffee.com/1.0.0/widget.prod.min.js');
    script.setAttribute('data-id', 'dev_harry');
    script.setAttribute('data-description', 'Support me on Buy me a coffee!');
    script.setAttribute('data-message', 'Your visit means a lot. Support us with a coffee!');
    script.setAttribute('data-color', '#40DCA5');
    script.setAttribute('data-position', 'Right');
    script.setAttribute('data-x_margin', xMargin); // Set the x-margin dynamically
    script.setAttribute('data-y_margin', yMargin); // Set the y-margin dynamically

    document.head.appendChild(script);

    // Clean up function to remove the script when the component is unmounted
    return () => {
      document.head.removeChild(script);
    };
  }, []); // Empty dependency array to ensure the effect runs only once when the component mounts


const navigate = useNavigate();
return (
  <div className="flex flex-col min-h-screen bg-slate-800 m-0 p-0 mx-auto w-full">
    <div className="py-4 px-6 flex flex-col max-w-4xl md:mx-auto w-full">
      <div className="flex md:flex-row flex-col justify-between items-center w-full">
        <img src={logo} className="md:h-24 h-20" alt="create react app logo" />
        <div className="flex space-x-4 md:mt-0 mt-8">
          <button onClick={() => navigate('/')} className="relative inline-block text-lg group">
            <span className="relative z-10 block px-5 py-3 overflow-hidden font-medium leading-tight text-gray-800 transition-colors duration-300 ease-out border-2 border-gray-900 rounded-lg group-hover:text-white">
              <span className="absolute inset-0 w-full h-full px-5 py-3 rounded-lg bg-gray-50"></span>
              <span className="absolute left-0 w-48 h-48 -ml-2 transition-all duration-300 origin-top-right -rotate-90 -translate-x-full translate-y-12 bg-gray-900 group-hover:-rotate-180 ease"></span>
              <span className="relative">Home</span>
            </span>
            <span className="absolute bottom-0 right-0 w-full h-12 -mb-1 -mr-1 transition-all duration-200 ease-linear bg-gray-900 rounded-lg group-hover:mb-0 group-hover:mr-0" data-rounded="rounded-lg"></span>
          </button>
          <button onClick={() => navigate('/how-to-use')} className="relative inline-block text-lg group">
            <span className="relative z-10 block px-5 py-3 overflow-hidden font-medium leading-tight text-gray-800 transition-colors duration-300 ease-out border-2 border-gray-900 rounded-lg group-hover:text-white">
              <span className="absolute inset-0 w-full h-full px-5 py-3 rounded-lg bg-gray-50"></span>
              <span className="absolute left-0 w-48 h-48 -ml-2 transition-all duration-300 origin-top-right -rotate-90 -translate-x-full translate-y-12 bg-gray-900 group-hover:-rotate-180 ease"></span>
              <span className="relative">How to use</span>
            </span>
            <span className="absolute bottom-0 right-0 w-full h-12 -mb-1 -mr-1 transition-all duration-200 ease-linear bg-gray-900 rounded-lg group-hover:mb-0 group-hover:mr-0" data-rounded="rounded-lg"></span>
          </button>
        </div>
      </div>

    </div>
    <AppRoutes />
    <Footer />
  </div>
);
}


