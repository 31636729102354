export interface Dependency {
    Name: string;
    Code: string;
    Description: string;
}

export const DependenciesArray: Dependency[] = [
    {
        Name: 'Axios',
        Code: 'axios',
        Description: 'Promise based HTTP client for the browser and node.js'
    },
    {
        Name: 'React Hook Form',
        Code: 'react-hook-form',
        Description: 'Performant, flexible and extensible forms with easy-to-use validation'
    },
    {
        Name: 'React Router Dom',
        Code: 'react-router-dom',
        Description: 'DOM bindings for React Router'
    },
    {
        Name: 'TailwindCSS',
        Code: '-D tailwindcss',
        Description: 'A utility-first CSS framework packed with classes like flex, pt-4, text-center and rotate-90 that can be composed to build any design, directly in your markup.'
    },
    {
        Name:'Tailwind Init',
        Code: 'tailwindcss init',
        Description: 'Create a tailwind.config.js file and a postcss.config.js file at the root of your project'
    },
    {
        Name:'Zod',
        Code: 'zod',
        Description: 'TypeScript-first schema declaration and validation library'
    },
    {
        Name:'React Tanstack Query',
        Code: '@tanstack/react-query',
        Description: 'Hooks for fetching, caching and updating asynchronous data in React'
    },
    {
        Name:'React Tanstack Query Devtools',
        Code: '@tanstack/react-query-devtools@4',
        Description: 'Devtools for React Query'
    },
    {
        Name:"React Toastify",
        Code: 'react-toastify',
        Description: 'React notification made easy'
    },
    {
        Name:"React Icons",
        Code: 'react-icons',
        Description: 'Include popular icons in your React projects easily with react-icons'
    },
    {
        Name:"React Helmet",
        Code: 'react-helmet',
        Description: 'A document head manager for React'
    },
    {
        Name:"React Chakra UI",
        Code:"@chakra-ui/react @emotion/react @emotion/styled framer-motion",
        Description: 'Simple, Modular & Accessible UI Components for your React Applications'
    },
    {
        Name:"React Chakra Icons",
        Code:"@chakra-ui/icons",
        Description: 'Chakra UI Icons'
    },
    {
        Name:"React Chakra Color Mode",
        Code:"@chakra-ui/color-mode",
        Description: 'Chakra UI Color Mode'
    },
    {
        Name:"React Chakra React Popper",
        Code:"@chakra-ui/react-popper",
        Description: 'Chakra UI React Popper'
    },
    {
        Name:"Yup",
        Code:"yup",
        Description: 'Yup is a schema builder for runtime value parsing and validation'
    },
    {
        Name:"CSS Loader",
        Code:"css-loader",
        Description: 'The css-loader interprets @import and url() like import/require() and will resolve them.'
    },
    {
        Name:"Material UI",
        Code:" @mui/material @emotion/react @emotion/styled",
        Description: 'React components for faster and easier web development. Build your own design system, or start with Material Design.'
    },
    {
        Name:"Material UI Icons",
        Code:"@mui/icons-material",
        Description: 'Material-UI Icons'
    },
    {
        Name:"Material UI Lab",
        Code:"@mui/lab",
        Description: 'The package for Material-UI lab components'
    },
    {
        Name:"Material UI System",
        Code:"@mui/system",
        Description: 'The package for Material-UI system components'
    },
    {
        Name:"Material UI Styles",
        Code:"@mui/styles",
        Description: 'The package for Material-UI styles'
    },
    {
        Name:"Material UI Unstyled",
        Code:"@mui/unstyled",
        Description: 'The package for Material-UI unstyled components'
    },
    {
        Name:"Framer Motion",
        Code:"framer-motion",
        Description: 'A production-ready motion library for React'
    },
    {
        Name:"React BootStrap",
        Code:"react-bootstrap bootstrap",
        Description: 'The most popular front-end framework Rebuilt for React'
    }
];