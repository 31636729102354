export const generatePythonScript = (
    projectName: string,
    packageManager: string,
    includeTypescript: boolean,
    dependencies: string[]
): string => {
    let script = `import os\n\n`;

    script += `def create_react_app():\n`;
    script += `    project_name = "${projectName}"\n`;
    script += `    typescript = "${includeTypescript ? 'yes' : 'no'}"\n`;
    script += `    package_manager = "${packageManager}"\n`;
    script += `    dependencies = ${JSON.stringify(dependencies)}\n\n`;

    script += `    print("Creating React project...")\n`;

    script += `    # Create React app\n`;
    script += `    command = f"npx create-react-app {project_name.lower()}"\n`;
    script += `    if typescript == 'yes':\n`;
    script += `        command += ' --template typescript'\n`;
    script += `    os.system(command)\n\n`;

    script += `    print("Moving to project directory...")\n`;
    script += `    # Move to project directory\n`;
    script += `    os.chdir(project_name)\n\n`;

    script += `    # Install dependencies based on package manager choice\n`;
    script += `    if package_manager == 'npm':\n`;
    script += `        os.system('npm install ' + ' '.join(dependencies))\n`;
    script += `    elif package_manager == 'yarn':\n`;
    script += `        os.system('yarn add ' + ' '.join(dependencies))\n`;
    script += `    elif package_manager == 'bun':\n`;
    script += `        os.system('bun install ' + ' '.join(dependencies))\n`;
    script += `    else:\n`;
    script += `        print("Invalid package manager choice!")\n\n`;

    script += `    print("Project created and dependencies installed successfully!")\n\n`;

    script += `if __name__ == "__main__":\n`;
    script += `    create_react_app()\n`;

    return script;
};



export const generateJavaScriptScript = (
    projectName: string,
    packageManager: string,
    includeTypescript: boolean,
    dependencies: string[]
) => {
    let script = `const readline = require('readline');\n`;
    script += `const { execSync } = require('child_process');\n`;
    script += `const path = require('path');\n\n`;

    script += `const rl = readline.createInterface({\n`;
    script += `    input: process.stdin,\n`;
    script += `    output: process.stdout\n`;
    script += `});\n\n`;

    script += `function createReactApp() {\n`;
    script += `    console.log("Creating React project...");\n`;
    script += `    // Create React app\n`;
    script += `    let command = \`npx create-react-app ${projectName.toLowerCase().trim()}\`;\n`;
    if (includeTypescript) {
        script += `    command += ' --template typescript';\n`;
    }
    script += `    execSync(command);\n\n`;

    script += `    // Move to project directory\n`;
    script += `    process.chdir(path.join(__dirname, '${projectName.toLowerCase()}'));\n\n`;

    script += `    console.log("Moving to project directory...");\n`;

    script += `    // Install dependencies based on package manager choice\n`;
    if (packageManager === 'npm') {
        script += `    execSync('npm install ${dependencies.join(' ').toLowerCase()}');\n`;
    } else if (packageManager === 'yarn') {
        script += `    execSync('yarn add ${dependencies.join(' ').toLowerCase()}');\n`;
    } else if (packageManager === 'bun') {
        script += `    execSync('bun install ${dependencies.join(' ').toLowerCase()}');\n`;
    } else {
        script += `    console.log("Invalid package manager choice!");\n`;
    }
    script += `    console.log("Project created and dependencies installed successfully!");\n`;
    script += `    rl.close();\n`;
    script += `}\n\n`;

    script += `createReactApp();\n`;

    return script;
}


export const generateBashScript = (
    projectName: string,
    includeTypescript: boolean,
    packageManager: string,
    dependencies: string[]
): string => {
    let script = `#!/bin/bash\n\n`;

    script += `# Create React app\n`;
    script += `echo "Creating React project..."\n`;
    script += `npx create-react-app ${projectName.toLowerCase()}`;
    if (includeTypescript) {
        script += ' --template typescript\n';
    } else {
        script += '\n';
    }

    script += `cd ${projectName}\n\n`;

    script += `# Install dependencies\n`;
    if (packageManager === 'npm') {
        script += `echo "Installing dependencies with npm..."\n`;
        script += `npm install ${dependencies.join(' ')}\n`;
    } else if (packageManager === 'yarn') {
        script += `echo "Installing dependencies with yarn..."\n`;
        script += `yarn add ${dependencies.join(' ')}\n`;
    } else if (packageManager === 'bun') {
        script += `echo "Installing dependencies with bun..."\n`;
        script += `bun install ${dependencies.join(' ')}\n`;
    } else {
        script += `echo "Invalid package manager choice!"\n`;
    }

    script += `echo "Project created and dependencies installed successfully!"\n`;

    return script;
};

