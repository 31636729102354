import { useEffect, useState } from "react";
import { DependenciesArray } from "./Dependencies";
import JSZip from "jszip";
import { saveAs } from 'file-saver';
import toast from "react-hot-toast";
import { generateBashScript, generateJavaScriptScript, generatePythonScript } from "../Utility/Utilities";

declare module 'react' {
    interface HTMLAttributes<T> extends AriaAttributes, DOMAttributes<T> {
        // extends React's HTMLAttributes
        directory?: string;        // remember to make these attributes optional....
        webkitdirectory?: string;
    }
}

function getRandomColor() {
    const letters = '456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * letters.length)];
    }
    return color;
}

export default function RequirementForm() {
    const [projectName, setProjectName] = useState('');
    const [selectedOption, setSelectedOption] = useState('');
    const [checkboxColors, setCheckboxColors] = useState<string[]>([]);
    const [selectedDependencies, setSelectedDependencies] = useState<string[]>([]);
    const [includeTypescript, setIncludeTypescript] = useState(false);
    const [script, setScript] = useState('');

    useEffect(() => {
        const colors: string[] = DependenciesArray.map(() => getRandomColor());
        setCheckboxColors(colors);
    }, []);

    const handlePMOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (selectedOption === event.target.value.toLocaleLowerCase()) {
            setSelectedOption('');
        } else {
            setSelectedOption(event.target.value.toLocaleLowerCase());
        }
    };

    const handleProjectNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setProjectName(event.target.value);
    };


    const handleIncludeTypescriptChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            setIncludeTypescript(true);
        } else {
            setIncludeTypescript(false);
        }
    }


    const handleDependencyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        console.log(event.target.value);
        const selectedDependency = event.target.value;
        if (selectedDependencies.includes(selectedDependency)) {
            const newSelectedDependencies = selectedDependencies.filter((dependency) => dependency !== selectedDependency);
            setSelectedDependencies(newSelectedDependencies);
        } else {
            setSelectedDependencies([...selectedDependencies, selectedDependency]);
        }
    }



    const generateScript = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        const buttonValue = e.currentTarget.value;

        if (!projectName){
            toast.error('Please enter a project name');
            return;
        }if (!selectedOption){
            toast.error('Please select a package manager');
            return;
        }


        const generateScriptForLanguage = (language: string, script: string) => {
            setScript(script);
            console.log(script);

            const zip = new JSZip();
            zip.file(`react-project-setup.zip.${language}`, script);
            zip.generateAsync({ type: "blob" })
                .then(function (content) {
                    saveAs(content, `react-project-setup.${language}.zip`);
                });
        };

        switch (buttonValue) {
            case 'python':
                generateScriptForLanguage('py', generatePythonScript(projectName, selectedOption, includeTypescript, selectedDependencies));
                break;
            case 'js':
                generateScriptForLanguage('js', generateJavaScriptScript(projectName, selectedOption, includeTypescript, selectedDependencies));
                break;
            case 'bash':
                generateScriptForLanguage('sh', generateBashScript(projectName, includeTypescript, selectedOption, selectedDependencies));
                break;
        }
    };



    return (
        <section className="py-1 max-w-7xl mx-auto">
            <div className="w-full lg:w-8/12 mx-auto mt-6">
                <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg border-0">
                    <div className="rounded-t bg-slate-900 text-white px-4 md:px-10 py-6">
                        <div className="text-center flex justify-between">
                            <h6 className="text-blueGray-700 text-xl font-bold">
                                Create Project Script
                            </h6>
                        </div>
                    </div>
                    <div className="flex-auto px-4 lg:px-10 py-10 pt-0 text-white bg-gray-900">
                        <form>
                            <div className="flex flex-wrap">
                                <div className="w-full lg:w-12/12">
                                    <div className="relative w-full mb-3">
                                        <label className="block uppercase text-xs font-bold mb-2" htmlFor="username">
                                            Project Name
                                        </label>
                                        <input onChange={handleProjectNameChange} type="text" id="username" className="border-0 text-black px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" placeholder="Enter project name" />
                                    </div>
                                </div>
                            </div>
                            <h6 className="text-blueGray-400 text-sm mt-3 mb-4 font-bold uppercase">
                                Include TypeScript
                            </h6>
                            <div className="flex space-x-4">
                                <div className='flex flex-col justify-between bg-gray-800'>
                                    <input
                                        checked={includeTypescript}
                                        onChange={handleIncludeTypescriptChange}
                                        type="checkbox"
                                        id="cb11"
                                        className={`appearance-none h-1 w-full bg-gray-400 
                                                        checked:bg-blue-500 checked:shadow-[0_0px_5px_blue]
                                                        transition-all duration-300 peer`}
                                    />
                                    <label
                                        htmlFor='cb11'
                                        className='p-1 flex flex-row justify-center px-6 peer-checked:text-blue-500 select-none text-white'
                                    >
                                        Typescript Template
                                    </label>
                                </div>
                            </div>

                            <hr className="mt-6 border-b-1 border-blueGray-300" />

                            <h6 className="text-blueGray-400 text-sm mt-3 mb-4 font-bold uppercase">
                                Choose you package manager
                            </h6>
                            <div className="flex space-x-4">
                                <div className='flex flex-col justify-between bg-gray-800'>
                                    <input
                                        checked={selectedOption === 'npm'}
                                        onChange={handlePMOptionChange}
                                        type="checkbox"
                                        id="cb4"
                                        value="NPM"
                                        className={`appearance-none h-1 w-full bg-gray-400 
                                                        checked:bg-yellow-300 checked:shadow-[0_0px_5px_yellow]
                                                        transition-all duration-300 peer`}
                                    />
                                    <label
                                        htmlFor='cb4'
                                        className='p-1 flex flex-row justify-center px-6 peer-checked:text-yellow-300 select-none text-white'
                                    >
                                        NPM
                                    </label>
                                </div>
                                <div className='flex flex-col justify-between bg-gray-800'>
                                    <input
                                        checked={selectedOption === 'yarn'}
                                        onChange={handlePMOptionChange}
                                        type="checkbox"
                                        id="cb5"
                                        value="YARN"
                                        className={`appearance-none h-1 w-full bg-gray-400 
                                                        checked:bg-green-500 checked:shadow-[0_0px_5px_green]
                                                        transition-all duration-300 peer`}
                                    />
                                    <label
                                        htmlFor='cb5'
                                        className='p-1 flex flex-row justify-center px-6 peer-checked:text-green-300 select-none text-white'
                                    >
                                        YARN
                                    </label>
                                </div>
                                {/* <div className='flex flex-col justify-between bg-gray-800'>
                                    <input
                                        checked={selectedOption === 'bun'}
                                        onChange={handlePMOptionChange}
                                        type="checkbox"
                                        id="cb6"
                                        value="BUN"
                                        className={`appearance-none h-1 w-full bg-gray-400
                                                        checked:bg-purple-600 checked:shadow-[0_0px_5px_purple]
                                                        transition-all duration-300 peer`}
                                    />
                                    <label
                                        htmlFor='cb6'
                                        className='py-1 px-6 flex flex-row justify-center peer-checked:text-purple-600 select-none text-white'
                                    >
                                        BUN
                                    </label>
                                </div> */}
                            </div>

                            <hr className="mt-6 border-b-1 border-blue" />

                            <h6 className="text-blueGray-400 text-sm mt-3 mb-4 font-bold uppercase">
                                Select Dependencies to install
                            </h6>
                            <div className="flex flex-wrap gap-4 bg-slate-800 rounded-b-lg p-4">
                                {DependenciesArray.map((dependency, index) => (
                                    <div key={index} className="flex flex-col justify-between bg-slate-900 rounded-md">
                                        <input
                                            checked={selectedDependencies.includes(dependency.Code)}
                                            onChange={handleDependencyChange}
                                            type="checkbox"
                                            id={`cbd${index}`}
                                            value={dependency.Code}
                                            className={`
                                                appearance-none h-1 w-full bg-gray-400 checked:bg-${checkboxColors[index]}
                                                checked:shadow-[0_0px_5px_purple] transition-all duration-300 peer
                                                ${selectedDependencies.includes(dependency.Code) && `checked:bg-${checkboxColors[index]}`}
                                                ${selectedDependencies.includes(dependency.Code) && 'checked:shadow-[0_0px_5px_purple]'}
                                            `}
                                            style={{ backgroundColor: selectedDependencies.includes(dependency.Code) ? checkboxColors[index] : '#1F2022' }}
                                        />
                                        <label
                                            htmlFor={`cbd${index}`}
                                            title={dependency.Description} 
                                            className="py-4 px-6 flex flex-row justify-center whitespace-nowrap"
                                            style={{ color: selectedDependencies.includes(dependency.Code) ? checkboxColors[index] : 'gray  ' }}
                                        >
                                            {dependency.Name}
                                        </label>
                                    </div>
                                ))}
                            </div>
                            <div className="flex md:flex-row flex-col  md:space-x-4 md:items-center w-full md:justify-between">
                                <button value="python" onClick={(e) => generateScript(e)} className="relative inline-flex items-center justify-center px-8 py-4 overflow-hidden mt-8 font-medium tracking-tighter text-white bg-gray-800 rounded-lg group">
                                    <span className="absolute w-0 h-0 transition-all duration-500 ease-out bg-[#3671A3] rounded-full group-hover:w-full group-hover:h-56"></span>
                                    <span className="absolute inset-0 w-full h-full -mt-1 rounded-lg opacity-30 bg-gradient-to-b from-transparent via-transparent to-gray-700"></span>
                                    <span className="relative">Generate Python Script</span>
                                </button>
                                <button value="js" onClick={(e) => generateScript(e)} className="relative inline-flex items-center justify-center px-8 py-4 overflow-hidden mt-8 font-medium tracking-tighter hover:text-black text-white bg-gray-800 rounded-lg group">
                                    <span className="absolute w-0 h-0 transition-all duration-500 ease-out bg-yellow-400 rounded-full group-hover:w-full group-hover:h-56"></span>
                                    <span className="absolute inset-0 w-full h-full -mt-1 rounded-lg opacity-30 bg-gradient-to-b from-transparent via-transparent to-gray-700"></span>
                                    <span className="relative">Generate JS Script</span>
                                </button>
                                <button value="bash" onClick={(e) => generateScript(e)} className="relative inline-flex hover:text-white items-center justify-center px-8 py-4 overflow-hidden mt-8 font-medium tracking-tighter  text-white bg-gray-800 rounded-lg group">
                                    <span className="absolute w-0 h-0 transition-all duration-500 ease-out bg-slate-950 rounded-full group-hover:w-full group-hover:h-56"></span>
                                    <span className="absolute inset-0 w-full h-full -mt-1 rounded-lg opacity-30 bg-gradient-to-b from-transparent via-transparent to-gray-700"></span>
                                    <span className="relative">Generate Bash Script</span>
                                </button>
                            </div>

                        </form>
                    </div>
                </div>
            </div>
        </section>
    )
}

