import { Route, Routes } from "react-router-dom";
import RequirementForm from "../Components/RequirementForm";
import ScriptGeneratorDocumentation from "../Components/ScriptGeneratorDocumentation";

export default function AppRoutes() {
    return (
        <Routes>
            <Route path="/" element={<RequirementForm />} />
            <Route path="*" element={<h1>Not Found</h1>} />
            <Route path="/how-to-use" element={<ScriptGeneratorDocumentation />} />
        </Routes>
    )
}